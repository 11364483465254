<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between flex-row-reverse lg:flex-row">
      <h1 class="page_title">{{ $route.meta.title }}</h1>
      <div class="flex">
        <router-link v-if="is_loaded" :to="{ name: `new-${fname}` }">
          <vs-button size="large"><span class="font-medium">新增{{ title }}</span></vs-button>
        </router-link>
        <span class="w-2"></span>
        <router-link v-if="is_loaded" :to="{ name: `${fname}s_rank` }">
          <vs-button size="large"><span class="font-medium">排序</span></vs-button>
        </router-link>
      </div>
    </div>

    <main v-if="is_loaded" class="bg-white rounded-2xl w-full pt-4">
      <!-- 搜尋 -->
      <section class="px-4 grid grid-cols-2 gap-2 lg:grid-cols-8">
        <vs-input class="lg:col-span-2" v-model="keyword" @blur="search" @keypress.enter="search" placeholder="搜尋" />
      </section>

      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min sm:w-full">
         <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="checkAllHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 位</span></vs-checkbox
          >
          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 位</span>
            <span v-else class="text-xs">共 {{ data.length }} 位</span>
          </vs-checkbox> 

          <!-- Head -->
          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="title_section" key="1">
              <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="delete_dialog = true" class="px-4 hover:bg-gray-background font-medium">刪除</button>
              </div>
            </div>
            <div v-else class="title_section" key="2">
              <span class="w-80">標題</span>
              <span style="width: 140px">修改時間</span>
              <span class="w-24">建立者</span>
              <span style="width: 140px">建立時間</span>
            </div>
          </transition>
        </div>

        <!-- Body -->
        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min sm:w-full">
          <div v-for="item in data" :key="item.id">
            <router-link
              :to="{
                name: `edit-${fname}`,
                params: {
                  id: item.id,
                },
                query: $route.query
              }"
              class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"
            >
             <vs-checkbox class="checkbox_section" @click.stop="checkHandleClick" v-model="selected" :val="item.id"><span class="ml-2">{{ item.no }}</span></vs-checkbox>

              <div class="title_section">
                <div class="w-80"><span>{{ item.name }}</span></div>
                <span style="width: 140px">{{ item.updated_at }}</span>
                <div class="w-24"><span>{{ item.creator }}</span></div>
                <span style="width: 140px">{{ item.created_at }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>

    <!-- pagination -->
    <div v-if="pagination.last_page > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.last_page" />
      <span class="text-xs text-gray-400">總共 {{ pagination.total }} 位</span>
    </div>

    <!-- 刪除會員 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ selected.length }} 位{{ title }}資料？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的{{ title }}資料無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="bathDelete" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'rneeds',
  components: {
  },
  data() {
    return {
      type: 4,
      fname: 'rneed',
      title: '其他需求',
      is_loaded: false,
      selectAll: false, //多選
      indeterminate: false, //多選
      selected: [], //多選
      keyword: '', //搜尋
      data: [],
      currentPage: 1, //分頁
      pagination: {}, //分頁
      delete_dialog: false, //刪除
    }
  },
  computed: {
    _selectedToStr() {
      return this.selected.toString()
    },
  },
  watch: {
  },
  created() {
    let routeQuery = JSON.parse(JSON.stringify(this.$route.query))
    if(this.$route.query != ""){
      let arr = [];
      for(let key in routeQuery){
        if(routeQuery[key]!= ""){
          arr.push([key,routeQuery[key]])
        }
      }
      this.loadData(arr)
    }else{
      this.loadData([['page', 1],['keyword', '']])
    }
  },
  methods: {
    // 讀取列表
    loadData(arr) {
      // 搜尋-------------------
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          if(item[1] != ''){
            routeQuery[item[0]] = item[1]
            if(item[0] == 'page') this.currentPage = item[1]
            else if(item[0] == 'keyword') this.keyword = item[1]
          } else{
            delete routeQuery[item[0]]
          } 
        })
      }
      this.$router.replace({ query: routeQuery }).catch(err => {err})
      // End搜尋-------------------
      this.$axios({
        url: 'backstage/rooms/devices',
        method: 'get',
        params: {
          type: this.type,
          page: routeQuery.page ? routeQuery.page : 1,
          q: routeQuery.keyword ? routeQuery.keyword : '',
          sort: 'seq-asc'
        },
      }).then((res) => {
        if (res.data.status) {
          this.is_loaded = true
          this.data = res.data.data
          this.pagination = res.data.meta
          //加入流水號
          for(let key in this.data) {
            this.data[key]['id'] = this.data[key]['id'].toString();
            this.data[key]['no'] = ((this.pagination.current_page-1)*this.pagination.per_page) + 1 + parseInt(key)
          }
        } else {
          this.$myVS.openNoti('ERROR', res.data.Message, 'danger', 3000)
        }
      })
    },
    // 搜尋
    search(e) {
      if (this.keyword === this.keywordBAK) return
      this.currentPage = 1
      this.loadData([
        ['page', 1],
        ['keyword', this.keyword],
      ])
      this.keywordBAK = this.keyword
    },
    // 分頁
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.loadData([['page', this.currentPage],['keyword', this.keyword]])
    },
    // 多選---------------------------------------------------------------------------------------
    // 單選
    checkHandleClick(e) {
      setTimeout(() => {
        if (this.selected.length > 0 && this.selected.length < this.data.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === this.data.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    // 全選
    checkAllHandleClick(e) {
      let value = e.target.checked
      if (value) {
        for (let i = 0; i < this.data.length; i++) {
          this.selected.push(this.data[i].id)
        }
      } else {
        this.selected = []
      }
    },
    // End多選---------------------------------------------------------------------------------------
    // 批次刪除
    bathDelete() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'backstage/rooms/device',
        method: 'delete',
        data: {
          type: this.type,
          ids: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.status) {
          this.selected = []
          this.indeterminate = false
          this.selectAll = false
          loading.close() // 關閉按鈕loading
          this.delete_dialog = false // 關閉dialog
          this.loadData([['page', 1],['keyword', '']])
          this.$myVS.openNoti(res.data.message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min sm:w-full;
  .title {
    @apply w-48 sm:flex-grow;
  }
}

.filter_section {
  @apply px-4 flex flex-wrap;

  .filter_box {
    @apply bg-gray-200 rounded-xl h-10 px-3 space-x-1
    flex items-center justify-center mr-2 mb-2;
  }
}
</style>
